import * as React from 'react';

import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';

import dataProvider from './libs/dataProvider';
import authProvider from './business_context/auth/authProvider';

import DashboardScreen from './business_context/dashboard/screens/DashboardScreen';

import AdvertisementList from './business_context/service_catalog/screens/AdvertisementList';
import AdvertisementEdit from './business_context/service_catalog/screens/AdvertisementEdit';
import PromotionsList from './business_context/promotions/screens/PromotionsList';
import PromotionsCreate from './business_context/promotions/screens/PromotionsCreate';
import ProviderPendingEdit from './business_context/dashboard/screens/ProviderPendingEdit';
import ProviderPendingList from './business_context/dashboard/screens/ProviderPendingList';
import TermsCreate from './business_context/terms/screens/TermsCreate';
import TermsList from './business_context/terms/screens/TermsList';
import CouponsList from './business_context/coupons/screens/CouponsList';
import CouponsCreate from './business_context/coupons/screens/CouponsCreate';
import CouponsEdit from './business_context/coupons/screens/CouponsEdit';
import CadasterProviderList from './business_context/cadasterProviders/screens/CadasterProvidersList';
import CadasterProviderEdit from './business_context/cadasterProviders/screens/CadasterProvidersEdit';
import CadasterClientEdit from './business_context/cadasterClients/screens/CadasterClientsEdit';
import CadasterClientList from './business_context/cadasterClients/screens/CadasterClientsList';
import AdvertisementShow from './business_context/service_catalog/screens/AdvertisementShow';
import WithdrawsList from './business_context/withdraws/screens/WithdrawsList';
import { RequiredDocumentsList } from './business_context/requireddocuments/screens/RequiredDocumentsList';
import RequiredDocumentsCreate from './business_context/requireddocuments/screens/RequiredDocumentsCreate';

const App = () => (
  <Admin
    dashboard={DashboardScreen}
    authProvider={authProvider}
    dataProvider={dataProvider}
    requireAuth
  >
    {/* <Resource name="users" list={ListGuesser} /> */}
    <Resource
      name="advertisements"
      list={AdvertisementList}
      show={AdvertisementShow}
      edit={AdvertisementEdit}
      options={{ label: 'Anúncios' }}
    />
    <Resource
      name="promotions"
      list={PromotionsList}
      create={PromotionsCreate}
      options={{ label: 'Imagens Promocionais' }}
    />
    <Resource
      name="controlProviders"
      list={ProviderPendingList}
      edit={ProviderPendingEdit}
      options={{ label: 'Controle de Prestadores' }}
    />
    <Resource
      name="terms"
      list={TermsList}
      create={TermsCreate}
      options={{ label: 'Termos de aceite' }}
    />
    <Resource
      name="coupons"
      list={CouponsList}
      create={CouponsCreate}
      edit={CouponsEdit}
      options={{ label: 'Cupons de desconto' }}
    />
    <Resource
      name="cadasterProviders"
      list={CadasterProviderList}
      edit={CadasterProviderEdit}
      options={{ label: 'Cadastro de Prestadores' }}
    />
    <Resource
      name="cadasterClients"
      list={CadasterClientList}
      edit={CadasterClientEdit}
      options={{ label: 'Cadastro de Clientes' }}
    />
    <Resource
      name="withdraws"
      list={WithdrawsList}
      options={{ label: 'Saques Disponíveis' }}
    />
    <Resource
      name="requiredDocuments"
      list={RequiredDocumentsList}
      create={RequiredDocumentsCreate}
      options={{ label: 'Documentos obrigatórios do prestador' }}
    />
  </Admin>
);

export default App;
