import { ArrayField, BooleanField, Datagrid, ImageField, NumberField, SelectField, Show, Tab, TabbedShowLayout, TextField } from "react-admin";


const AdvertisementShow = () => {
    const days = [
        {id: 0, name: 'Monday'},
        {id: 1, name: 'Tuesday'},
        {id: 2, name: 'Wednesday'},
        {id: 3, name: 'Thursday'},
        {id: 4, name: 'Friday'},
        {id: 5, name: 'Saturday'},
        {id: 6, name: 'Sunday'},
    ]

return (
    <Show>
        <TabbedShowLayout spacing={1}>
            <Tab label="Ads">
                <TextField source="id" />
                <TextField source="title" />
                <TextField source="description" />
                <ImageField source="imageUrl" />
                <BooleanField source="enabled" />
            </Tab>

            <Tab label="Services">
                <ArrayField source="services">
                    <Datagrid align="center" bulkActionButtons={false}>
                        <TextField source="id" />
                        <TextField source="title" />
                        <NumberField source="price" />
                        <TextField source="category" />
                        <TextField source="deleted" />
                    </Datagrid>
                </ArrayField>         
            </Tab>

            <Tab label="Opening Time">
                <ArrayField source="openingTime">
                    <Datagrid align="center" bulkActionButtons={false}>
                        <SelectField source="weekday" choices={days}/>
                        <BooleanField source="openingTime.open" />
                        <TextField source="openingTime.start" />
                        <TextField source="openingTime.end" />
                    </Datagrid>
                </ArrayField>         
            </Tab>

        </TabbedShowLayout>
    </Show>
);
}

export default AdvertisementShow;