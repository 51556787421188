import * as React from "react";
import { Create, SimpleForm, TextInput, NumberInput, BooleanInput, FormDataConsumer, required } from 'react-admin';

const CouponsCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source={'code'} label="Código" validate={[required()]} />
      <NumberInput source={'discount'} label="Porcentagem de desconto" />
      <NumberInput source={'absolute_price'} label="Preço absoluto" />
      <NumberInput source={'activation_limit'} label="Limite de ativações" />
      <NumberInput source={'days_to_expire_after_activation'} label="Dias para expirar após ativação" />
      <BooleanInput source={'activate'} label="Ativar" />
    </SimpleForm>
  </Create>
);

export default CouponsCreate;
