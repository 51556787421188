import React, { useEffect, useState } from 'react';
import {
  useDataProvider,
  Datagrid,
  TextField,
  EditButton,
  ImageField,
  List,
} from 'react-admin';

const ProviderPendingList = () => {
  const dataProvider = useDataProvider();
  const [dynamicColumns, setDynamicColumns] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dataProvider.getList('controlProviders', {
          pagination: { page: 1, perPage: 10 },
          sort: { field: 'id', order: 'ASC' },
          filter: {},
        });

        // Interceptar a resposta e extrair as colunas dinâmicas
        if (response.data && response.data.length > 0) {
          const firstItem = response.data[0];

          const dynamicFields = Object.keys(firstItem).filter((propriedade) =>
            propriedade.startsWith('document')
          );
          setDynamicColumns(dynamicFields);
        }
      } catch (error) {
        console.error('Erro ao obter dados:', error);
      }
    };

    fetchData();
  }, [dataProvider]); // Certifique-se de passar o dataProvider como dependência

  return (
    <List>
      <Datagrid>
        {/* Colunas estáticas */}
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />

        {/* Colunas dinâmicas */}
        {dynamicColumns.map((column) => (
          <ImageField source={column} />
        ))}

        <TextField source="registration" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ProviderPendingList;
