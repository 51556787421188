import * as React from "react";

import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';

const RequiredDocumentsCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="documentName" />
    </SimpleForm>
  </Create>
);

export default RequiredDocumentsCreate;
