import React from 'react';
import { TextInput, TextInputProps } from 'react-admin';

interface TimeInputProps extends Omit<TextInputProps, 'source'> {
    source: string;
  }

const TimeInput: React.FC<TimeInputProps> = ({ source, ...rest }) => {
  const formatTime = (value: string) => {
    const numericValue = getNumericValue(value);

    // Format to hh:mm or ''
    if (numericValue.length === 2) {
      return `${numericValue}:`;
    } else if (numericValue.length === 3) {
      return `${numericValue[0]}${numericValue[1]}:${numericValue[2]}`;
    } else if (numericValue.length >= 4) {
      return `${numericValue[0]}${numericValue[1]}:${numericValue[2]}${numericValue[3]}`;
    } else if (numericValue.length === 0) {
      return '';
    }

    return value;
  };

  const validateTime = (value: string) => {
    const numericValue = getNumericValue(value);

    if (numericValue.length !== 4) {
      return 'The hour must have exactly 4 numbers.';
    }
    const timePattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  if (!timePattern.test(value)) {
    return 'The value must be in valid time format (example: 23:59).';
  }

    return undefined;
  };

  const parseTime = (value:string) => {
    const numericValue = getNumericValue(value)

    // Analise o valor para retornar um formato numérico
    if (numericValue.length >= 2) {
      return `${numericValue.slice(0, 2)}:${numericValue.slice(2)}`;
    }

    return value;
  };

  const format = (value:string) => {
    return formatTime(value);
  };

  const parse = (value:string) => {
    return parseTime(value);
  };

  return (
    <TextInput
      source={source}
      format={format}
      parse={parse}
      validate={validateTime}
      {...rest}
    />
  );
};

function getNumericValue(value: string) {
  const numericValue = value.replace(/\D/g, '');

  return numericValue
}

export default TimeInput;
