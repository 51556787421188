import { BooleanField, Datagrid, EditButton, List, TextField } from "react-admin"
import React from 'react';


const CadasterClientList = () => (
 <List>
    <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="cpf" />
        <TextField source="phone" />
        <TextField source="lead" />
        <TextField source="userId" />
        <BooleanField source="blocked" />
        <EditButton />
    </Datagrid>
</List>
);

export default CadasterClientList;