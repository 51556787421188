import React from 'react';

import { Datagrid, List, TextField, UrlField} from 'react-admin';

const TermsList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <TextField source="type" />
      <UrlField source="link" />
    </Datagrid>
  </List>
);

export default TermsList;