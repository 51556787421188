import React from 'react';
import { Datagrid, Button, List, TextField, NumberField, RaRecord, useRecordContext, useRefresh } from 'react-admin';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { apiUrl, httpClient } from '../../../libs/dataProvider';

const defaultIcon = <PriceCheckIcon />;

export const ConfirmWithdrawButton = <RecordType extends RaRecord = any>(props: any) => {
    const {
        icon = defaultIcon,
        scrollToTop = true,
        ...rest
    } = props;
    const record = useRecordContext(props);
    const refresh = useRefresh();

    if (!record) return null;

    const handleClick = () => { 

        httpClient(`${apiUrl}/withdraw/forceWithdrawAsPaid`, {
            method: 'PATCH',
            body: JSON.stringify({providerId: record.id}),
        }).then(() => refresh());
    }

    return (
        <Button
            label={"Confirmar saque"}
            onClick={(e) => {e.stopPropagation(); handleClick();}}
            {...(rest as any)}
        >
            {icon}
        </Button>
    );
};


const WithdrawsList = ()=> (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="provider_name" />
            <TextField source="provider_pix_key" />
            <NumberField source="available" locales="pt-BR" options={{ style: 'currency', currency: 'BRL' }}/>
            <ConfirmWithdrawButton />
]        </Datagrid>
    </List>
);


export default WithdrawsList;