import React from 'react';
import {
  Datagrid,
  List,
  EditButton,
  TextField,
  ImageField,
  BooleanField,
} from 'react-admin';

const AdvertisementList = (props: any) => (
  <List {...props} sort={{ field: 'id', order: 'ASC' }}>
    <Datagrid rowClick="edit">
      <TextField source="id" sortable />
      <TextField source="title" sortable />
      <TextField source="description" />
      <ImageField source="imageUrl" />
      <BooleanField source="enabled" />
      <EditButton />
    </Datagrid>
  </List>
);

export default AdvertisementList;
