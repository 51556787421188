import React from 'react';
import { Create, SimpleForm, BooleanInput, Edit } from 'react-admin';



const CouponsEdit = () => (
  <Edit>
    <SimpleForm>
      <BooleanInput source={'activate'} label="Ativar" />
    </SimpleForm>
  </Edit>
)


export default CouponsEdit;