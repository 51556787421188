import React from 'react';
import { Datagrid, EditButton, List, TextField, BooleanField, NumberField } from 'react-admin';



const CouponsList = ()=> (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="code" />
            <BooleanField source="activate" />
            <NumberField source="discount" />
            <NumberField source="absolute_price" />
            <NumberField source="activation_limit" />
            <NumberField source="days_to_expire_after_activation" />
            <NumberField source="times_activated" />
            <EditButton />
        </Datagrid>
    </List>
);


export default CouponsList;