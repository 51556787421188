import * as React from 'react';
import api from "../../../infra/api";
import { Card, CardHeader, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ClientProfile } from '../dto/client.dto';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Nome', width: 300 },
  { field: 'cpf', headerName: 'CPF', width: 200 },
  { field: 'phone', headerName: 'Telefone', width: 200 },
  {
    field: 'photo_url',
    headerName: 'Foto de perfil',
    type: 'photo',
    width: 350,
  },
];

const ClientsList = () => {
  const [rows, setRows] = React.useState<ClientProfile[]>([]);

  React.useEffect(() => {
    api.get<ClientProfile[]>('/panel/charts/clients-last-month', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((result) => {
      setRows(result.data);
    }).catch((err) => console.log(err));
  }, [])

  return (
    <Card style={{
      minHeight: 400, width: '100%', marginTop: '10px', marginBottom: '10px',
    }}>
      <CardHeader title="Clientes que acessaram nos últimos 30 dias" />

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
      />
    </Card >
  );

}

export default ClientsList;
