import React from 'react';

import { Datagrid, List, TextField, ImageField, CreateButton, UrlField} from 'react-admin';

const PromotionsList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <ImageField source="imageUrl" />
      <TextField source="description" />
      <UrlField source="link" />
    </Datagrid>
  </List>
);

export default PromotionsList;
