import { fetchUtils, DataProvider, Identifier } from 'react-admin';
import { stringify } from 'query-string';
import FormData from 'form-data';
import { sortBy } from 'lodash';
import api from '../infra/api';
import { AxiosResponse } from 'axios';

export const apiUrl = `${process.env.REACT_APP_BACKEND_URL}`;

const resourceByOptions: { [key: string]: string } = {
  advertisements: 'advertisement',
  promotions: 'promotion',
  controlProviders: 'controlprovider',
  terms: 'term',
  coupons: 'coupon',
  withdraws: 'withdraw',
  cadasterProviders: 'cadasterprovider',
  cadasterClients: 'cadasterclient',
  requiredDocuments: 'requireddocument',
};

export const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    let defaultHeaders: { [key: string]: string } = {};

    if (options.method !== 'DELETE') {
      defaultHeaders = { Accept: 'application/json' };
    } else {
      defaultHeaders = { 'Content-Type': 'text/plain' };
    }

    options.headers = new Headers(defaultHeaders);
  }

  const token = localStorage.getItem('token');
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  return fetchUtils.fetchJson(url, options);
};

const verifyIfHasFileOnParams = (params: any): boolean => {
  for (const [key, value] of Object.entries(params.data)) {
    const param = params.data[key];
    if (param && param.rawFile) {
      return true;
    }
  }
  return false;
};

const sendPostWithFiles = (endpoint: string, params: any) => {
  const formData = new FormData();
  const token = localStorage.getItem('token');

  for (const [key, value] of Object.entries(params.data)) {
    if (params.data[key].rawFile) {
      formData.append(key, params.data[key].rawFile);
    } else {
      formData.append(key, value as string | Blob);
    }
  }

  return api
    .post(endpoint, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res: AxiosResponse) => {
      const { status, headers, data } = res;
      return Promise.resolve({ status, headers, data });
    });
};

const customDataProvider: DataProvider = {
  getList: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      filter: JSON.stringify(params.filter),
    };

    const url = `${apiUrl}/${resourceByOptions[resource]}?${stringify(query)}`;

    return httpClient(url).then(({ json }) => {
      let data = json[`${resourceByOptions[resource]}s`];

      // Ordenar os dados no frontend
      data = sortBy(data, [field]);
      if (order === 'DESC') {
        data = data.reverse();
      }

      // Paginar os dados no frontend
      const start = (page - 1) * perPage;
      const end = page * perPage;
      const paginatedData = data.slice(start, end);

      return {
        data: paginatedData,
        total: data.length,
      };
    });
  },

  getOne: (resource: string, params: any) =>
    httpClient(
      `${apiUrl}/${resourceByOptions[resource]}/details/${params.id}`
    ).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${apiUrl}/${resourceByOptions[resource]}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      let data = json;

      // Ordenar os dados no frontend
      data = sortBy(data, [field]);
      if (order === 'DESC') {
        data = data.reverse();
      }

      // Paginar os dados no frontend
      const start = (page - 1) * perPage;
      const end = page * perPage;
      const paginatedData = data.slice(start, end);

      return {
        data: paginatedData,
        total: data.length,
      };
    });
  },

  create: (resource: string, params: any) => {
    const endpoint = `${apiUrl}/${resourceByOptions[resource]}`;

    if (verifyIfHasFileOnParams(params)) {
      return sendPostWithFiles(endpoint, params);
    } else {
      return httpClient(endpoint, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }
  },

  update: (resource: string, params: any) =>
    httpClient(`${apiUrl}/${resourceByOptions[resource]}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource: string, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource: string, params: any) => {
    const url = `${apiUrl}/${resourceByOptions[resource]}`;

    return httpClient(`${url}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource: string, params: any) => {
    const url = `${apiUrl}/${resourceByOptions[resource]}`;

    return Promise.all(
      params.ids.map((id: Identifier) =>
        httpClient(`${url}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then((results: any[]) => {
      return { data: results.map((result) => result.id) };
    });
  },
};

export default customDataProvider;
