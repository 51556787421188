import api from '../../infra/api';
import { AuthProvider } from 'react-admin';

interface DataLogin {
  username: string;
  password: string;
}

interface AuthResponse {
  token: string;
}

const authProvider: AuthProvider = {
  login: async (data: DataLogin) => {
    const { username, password } = data;

    await api.post<AuthResponse>('/auth/login', { email: username, password: password })
      .then((result) => {
        if (result) {
          localStorage.setItem('email', username);
          localStorage.setItem('token', result.data.token);
        }
      })
      .catch((err) => console.error(err));
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }

    return Promise.resolve();
  },
  getIdentity: () => {
    return Promise.resolve({
      id: 'user',
      fullName: `${localStorage.getItem('email')}`,
    });
  },
  getPermissions: () => {
    return Promise.resolve('');
  }
};

export default authProvider;
