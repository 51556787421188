import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput } from "react-admin";
import PasswordBooleanInput from "../../../components/PassWordBooleanInput";


const CadasterClientEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="cpf" />
            <TextInput source="phone" />
            <NumberInput source="lead" />
            <BooleanInput source="blocked" />
            <PasswordBooleanInput source='password' label='New Password'/>
        </SimpleForm>
    </Edit>
);

export default CadasterClientEdit;
