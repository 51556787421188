import * as React from "react";

import api from "../../../infra/api";
import { ResponsiveLine, Serie } from '@nivo/line'
import { Box, Card, CardHeader, Grid, Paper } from '@mui/material';

import ClientsList from '../screens/ClientsList';
import ProvidersList from "../screens/ProvidersList";

const Dashboard = () => {
  const [usersByMonth, setUsersByMonth] = React.useState<Serie[]>([]);
  const [newServicesByMonth, setNewServicesByMonth] = React.useState<Serie[]>([]);

  React.useEffect(() => {
    api.get<Serie[]>('/panel/charts/users-by-month', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((result) => {
      setUsersByMonth(result.data);
    }).catch((err) => console.log(err));

    api.get<Serie[]>('/panel/charts/new-services-by-month', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((result) => {
      setNewServicesByMonth(result.data);
    }).catch((err) => console.log(err));
  }, [])

  return (
    <Grid container direction={"column"}>
      < Card style={{ marginTop: '20px', maxHeight: '350px', padding: '0 65px 65px 65px' }
      }>
        <CardHeader title="Quantidade de novos usuários por mês" />
        <ResponsiveLine
          data={usersByMonth}
          margin={{ top: 10, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: false,
            reverse: false
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Mês',
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            format: e => Math.floor(e) === e && e,
            tickRotation: 0,
            legend: 'Quantidade',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 100,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      </Card >

      <Card style={{ marginTop: '20px', maxHeight: '350px', padding: '0 65px 65px 65px' }}>
        <CardHeader title="Novos serviços por mês" />
        <ResponsiveLine
          data={newServicesByMonth}
          margin={{ top: 10, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: false,
            reverse: false
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Mês',
            legendOffset: 36,
            legendPosition: 'middle'
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            format: e => Math.floor(e) === e && e,
            tickRotation: 0,
            legend: 'Quantidade',
            legendOffset: -40,
            legendPosition: 'middle'
          }}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 100,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
        />
      </Card>

      <ClientsList />
      <ProvidersList />
    </Grid >
  );

}

export default Dashboard;
