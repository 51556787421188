import { Datagrid, DeleteButton, List, TextField } from "react-admin";

export const RequiredDocumentsList = ()=> (
  <List>
      <Datagrid>
          <TextField source="id" />
          <TextField source="documentName" />
          <DeleteButton />
]        </Datagrid>
  </List>
);
