
import {
  Edit,
  TextInput,
  BooleanInput,
  SimpleFormIterator,
  ArrayInput,
  SelectInput,
  FormDataConsumer,
  SimpleForm,
  required,
  NumberInput,
  useUpdate,
  SelectField,
} from 'react-admin';
import TimeInput from '../../../components/TimeInput';

const AdvertisementEdit = () => {
  const days = [
    {id: 0, name: 'monday'},
    {id: 1, name: 'tuesday'},
    {id: 2, name: 'wednesday'},
    {id: 3, name: 'thursday'},
    {id: 4, name: 'friday'},
    {id: 5, name: 'saturday'},
    {id: 6, name: 'sunday'},
]

const categoryService = [
  {id: 'hair', name: 'hair'},
  {id: 'nails', name: 'nails'},
  {id: 'makeup', name: 'makeup'},
  {id: 'spa', name: 'spa'},
  {id: 'eyebrows', name: 'eyebrows'}, 
  {id: 'shaving', name: 'shaving'},
]
const [update, { isLoading, error }] = useUpdate();

const validateAdvertisementResponse = (formData: any) => {
  let daysWeek: any = {};
  formData.openingTime.forEach((item: any) => {
    
    if (item.openingTime.open) {
      daysWeek[`${days[item.weekday].name}Start`] = item.openingTime.start ;
      daysWeek[`${days[item.weekday].name}End`] = item.openingTime.end;
    } 
    else {
      daysWeek[`${days[item.weekday].name}Start`] = null ;
      daysWeek[`${days[item.weekday].name}End`] = null;
    }
    })

  const data ={title: formData.title, description: formData.description, enabled: formData.enabled, services: formData.services, ...daysWeek }
  
  update('advertisements', {id: formData.id, data: data})
}

return (
  <Edit>
    <SimpleForm onSubmit={validateAdvertisementResponse}>
      <TextInput source="title" />
      <TextInput source="description" />
      <BooleanInput source="enabled" />
      <ArrayInput source="services"  >
        <SimpleFormIterator disableAdd disableRemove disableReordering >
          <TextInput source="id" disabled/>
          <TextInput source="title" />
          <NumberInput source="price" inputMode='decimal' />
          <SelectInput source="category" choices={categoryService} validate={required()}/>
          <BooleanInput source="deleted" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="openingTime">
      <SimpleFormIterator disableAdd disableRemove disableReordering >
          <SelectField source="weekday" choices={days} sx={{p:5, fontSize:20, fontWeight:10,}}/>
          <BooleanInput source="openingTime.open" label='Open'/>
          <FormDataConsumer>
                        {({
                            formData, // The whole form data
                            scopedFormData, // The data for this item of the ArrayInput
                            getSource, // A function to get the valid source inside an ArrayInput
                            ...rest
                      
                        }) => {
                          if ( getSource && scopedFormData && scopedFormData.openingTime.open ) {
                            return (
                              <div>
                                <TimeInput source={getSource('openingTime.start')} label='Start'/>
                                <TimeInput source={getSource('openingTime.end')} label='End' sx={{marginLeft: 2}}/>
                              </div>
                            )
                          }
                        }
                            
                        }
            </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
}

export default AdvertisementEdit;
