import * as React from "react";

import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

const type = [
    { id: 'PROVIDER', name: 'Provider' },
    { id: 'CLIENT', name: 'Client' },
  ];

const TermsCreate = () => (
  <Create>
    <SimpleForm>
      <SelectInput source="type" choices={type} />
      <TextInput source="link" />
    </SimpleForm>
  </Create>
);

export default TermsCreate;
