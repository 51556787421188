import React, { useState } from 'react';
import { BooleanInput, PasswordInput } from 'react-admin';

const PasswordBooleanInput = (props: any) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleToggle = (event: any) => {
    setIsVisible(event.target.checked);
  };


  return (
    <div>
      <BooleanInput source='change_password' label='Change Password' onChange={handleToggle} />
      {isVisible && <PasswordInput {...props} />}
    </div>
  );
};

export default PasswordBooleanInput;