import React, { useState } from "react";
import { Edit, SimpleForm, SelectInput, TextInput} from "react-admin";

const status = [
  { id: true, name: 'Aprovar' },
  { id: false, name: 'Reprovar' },
];


const ProviderPendingEdit = () => {
  const [showMotivoInput, setShowMotivoInput] = useState(false);
  console.log(`showMotivoInput: ${showMotivoInput}`)

  const handleStatusChange = (event: any) => {
    const selectedStatus = event.target.value;
    // Verifica se o status selecionado é "Aprovar"
    console.log(`selectedStatus: ${selectedStatus}`)
    if (selectedStatus === false) {
      setShowMotivoInput(true);
    } else {
      setShowMotivoInput(false);
    }
  };

  return (
    <Edit>
      <SimpleForm>
        <SelectInput source="status" choices={status} resettable onChange={handleStatusChange} />
        {showMotivoInput && <TextInput source="motivo" required={true} />}
      </SimpleForm>
    </Edit>
  );
};

export default ProviderPendingEdit;
