import { BooleanInput, Edit, SimpleForm, TextInput } from "react-admin";
import PasswordBooleanInput from "../../../components/PassWordBooleanInput";


const CadasterProviderEdit = () => {

    return (
        <Edit>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="cpf" />
            <TextInput source="cnpj" />
            <TextInput source="phone" />
            <BooleanInput source="blocked" />
            <PasswordBooleanInput source='password' label='New Password'/>
        </SimpleForm>
    </Edit>

    )
};

export default CadasterProviderEdit;

