import { Datagrid, EditButton, ImageField, List, TextField, BooleanField } from "react-admin"
import React from 'react';


const CadasterProviderList = () => (
 <List>
    <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="cpf" />
        <ImageField source="cpfUrl" label='Cpf image' />
        <TextField source="cnpj" />
        <ImageField source="cnpjUrl" label='Cnpj image'/>
        <TextField source="registration" />
        <ImageField source="photoUrl" label='Photo'/>
        <TextField source="phone" />
        <TextField source="userId" />
        <BooleanField source="blocked" />
        <EditButton />
    </Datagrid>
</List>
);

export default CadasterProviderList;