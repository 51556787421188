import * as React from "react";

import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
} from 'react-admin';

const PromotionsCreate = () => (
  <Create>
    <SimpleForm>
      <ImageInput source="picture" label="Imagem promocional" accept="image/*">
        <ImageField source="src" title="Imagem Promonocional" />
      </ImageInput>
      <TextInput source="description" />
      <TextInput source="link" />
    </SimpleForm>
  </Create>
);

export default PromotionsCreate;
